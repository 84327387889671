



























import {Component, Vue} from "vue-property-decorator";
import CircleImageButton from "@/components/CircleImageButton.vue";
import {ActiveCard} from "@/entity/ActiveCard";
import CloseSvg from "@/assets/vue-icons/Modal/CloseSvg.vue";

@Component({
    components: {CloseSvg, CircleImageButton}
})
export default class WarningUpdateProfileModal extends Vue{
    get width(){
        return "308px"
    }

    get height(){
        return "420px"
    }

    onClose(){
        this.$modal.hide("WarningUpdateProfileModal");
    }

    onDecline(){
        this.onClose();
    }

    onConfirm(){
        this.onClose();
        this.$emit("confirm");
    }
}
