<template>
  <div class="profile">
    <div class="profile__form form">
      <alert-text
          class="form__alert"
          :value="alertText"
          v-if="alertText.length > 0"
          size="md"
          :success="isSuccess"/>
      <p class="form__title">ПЕРСОНАЛЬНЫЕ ДАННЫЕ</p>
      <input-profile
          placeholder="Имя"
          v-model="firstName"
          :is-trim-enable="true"
          class="form__input form__input_first"
          size="lg"/>
      <input-profile
          placeholder="Фамилия"
          v-model="lastName"
          :is-trim-enable="true"
          class="form__input"
          size="lg"/>
      <p class="form__title">КОНТАКТНЫЕ ДАННЫЕ</p>
      <input-profile
          id="phone"
          placeholder="Номер телефона"
          type="tel"
          v-model="phone"
          :is-trim-enable="true"
          class="form__input form__input_first"
          size="lg"/>
      <input-profile
          placeholder="Email"
          v-model="email"
          :is-trim-enable="true"
          class="form__input"
          size="lg"/>
      <p class="form__title">ЛОГИН</p>
      <input-profile
          placeholder="логин"
          v-model="login"
          :is-trim-enable="true"
          :is-russian-symbols-disabled="true"
          :value="login"
          class="form__input form__input_first"
          size="lg"/>
      <p class="form__title">СМЕНА ПАРОЛЯ</p>
      <input-profile
          placeholder="Новый пароль"
          type="password"
          v-model="password"
          :is-trim-enable="true"
          class="form__input form__input_first"
          size="lg"/>
      <input-profile
          placeholder="Повторите новый пароль"
          type="password"
          v-model="repeatPassword"
          :is-trim-enable="true"
          class="form__input"
          size="lg"/>
      <div class="form__buttons">
        <ButtonPink
            class="form__button form__button_confirm"
            size="lg"
            value="СОХРАНИТЬ ИЗМЕНЕНИЯ"
            @click.native="updateProfile"
            :disabled="!isChanged || alertText.length > 0"/>
        <a
            class="form__button form__button_cancel"
            @click="reset">
          ОТМЕНА</a>
      </div>
    </div>
    <warning-update-profile-modal v-on:confirm="checkUpdateProfile"></warning-update-profile-modal>
    <SuccessUpdateTariffModal
      @exit="$router.push({name : 'ParentDashboard'})"/>
  </div>
</template>

<script>
    import IMask from "imask";
    import WarningUpdateProfileModal from "@/components/modal/WarningUpdateProfileModal";
    import ButtonPink from "../../../components/button/ButtonPink";
    import InputProfile from "../../../components/input/InputProfile";
    import AlertText from "../../../components/alert/AlertText";
    import SuccessUpdateTariffModal from "../../../components/modal/SuccessUpdateTariffModal";
    export default {
        name: "SettingsProfileView",
      components: {SuccessUpdateTariffModal, AlertText, InputProfile, ButtonPink, WarningUpdateProfileModal},

        data(){
            return {
                login: "",
                email: "",
                phone: "",
                password: "",
                repeatPassword: "",
                alertText: "",
                isSuccess: false,
                mask: null,
                isChanged : false,
                firstName : '',
                lastName : ''
            }
        },
        watch:{
            login:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            email:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            phone:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            password:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            repeatPassword:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            firstName:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            lastName:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            }
        },
        methods:{

            isValidEmail(email){
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            async updateProfile(){
                const profile = this.$store.getters.PROFILE
                if (this.firstName.length <= 1){
                    this.alertText = "Введите корректное имя"

                } else if (this.lastName.length <= 1){
                    this.alertText = "Введите корректную фамилию"
                }
                else if  (this.login.length === 0){
                    this.alertText = "Введите корректный логин"
                } else if (this.email.length === 0 || !this.isValidEmail(this.email)){
                    this.alertText = "Введите корректный адрес электронной почты"
                } else if (this.phone.length !== 16){
                    this.alertText = "Введите корректный номер телефона"
                } else if ((this.password.length > 0) &&(this.password.length < 6)){
                    this.alertText = "Длина пароля не должна быть менее 6 символов"
                } else if (this.password !== this.repeatPassword) {
                    this.alertText = "Пароли не совпадают"
                } else {
                    if (profile.userName !== this.login){
                        await this.$store.dispatch("CHECK_LOGIN_EXISTS",{login : this.login})
                        if ( this.$store.getters.IS_EXISTS_LOGIN){
                            this.alertText = "Ошибка! Логин занят"
                        } else {
                            if ((this.password.length > 0) || (profile.userName !== this.login)){
                                await this.$modal.show("WarningUpdateProfileModal")
                            } else {
                                await this.update(profile)
                            }
                        }
                    } else {
                        if ((this.password.length > 0) || (profile.userName !== this.login)){
                            await this.$modal.show("WarningUpdateProfileModal")
                        } else {
                            await this.update(profile)
                        }
                    }
                }
            },

            async update(profile){
                try{
                    const data = {
                        login : (profile.userName === this.login) ? "" : this.login,
                        email : (profile._email === this.email) ? "" : this.email,
                        phone : (profile._phone === this.phone) ? "" : this.phone,
                        password : this.password,
                        firstName : (profile._firstName === this.firstName) ? "" : this.firstName,
                        lastName : (profile._lastName === this.lastName) ? "" : this.lastName,
                    }

                    await this.$store.dispatch("UPDATE_MY_PROFILE",{dataObject : data})
                    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
                    this.password = ""
                    this.repeatPassword = ""
                    this.checkData()
                  this.$modal.show("SuccessUpdateTariffModal")
                } catch (e) {
                    this.alertText("что-то пошло не так")
                }
            },

            reset(){
                // const profile = this.$store.getters.PROFILE
                // this.login = profile.userName
                // this.email = profile._email
                // document.getElementById("phone").value = profile._phone
                // this.phone = profile._phone
                this.init()
                this.password = ""
                this.repeatPassword = ""
            },

            async checkUpdateProfile(){
                const profile = this.$store.getters.PROFILE
                await this.update(profile)
            },

            async exit(){
              await this.$store.dispatch("EXIT_FROM_ACCOUNT");

                await this.$router.push({name: "NewAuth"})
            },


          clearSpecial(text){
            return text.replace(/[^\w\s]/gi, '')
          },

            checkData(){
                const profile = this.$store.getters.PROFILE
                if (profile == null) return;
                this.alertText = ''
                this.isChanged = !(profile._lastName === this.lastName && profile._firstName === this.firstName
                    && profile.userName === this.login && profile._email === this.email
                    && this.clearSpecial(profile._phone) === this.clearSpecial(this.phone)
                    && this.password.length === 0 && this.repeatPassword.length === 0)
            },


            async init(){


                await this.$store.dispatch("GET_PROFILE")
                const profile = this.$store.getters.PROFILE
                this.login = profile.userName
                this.email = profile._email
              profile._phone = profile._phone.substring(1)
                document.getElementById("phone").value = profile._phone
                this.phone = profile._phone
                this.firstName = profile._firstName
                this.lastName = profile._lastName

            }
        },



        async mounted(){
            await this.init()
        }
    }
</script>

<style scoped lang="scss">
.profile{

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  &__form{
    display: flex;
    flex-direction: column;
    width: 305px;
    height: auto;
    margin-bottom: 200px;
  }
}

.form{
  margin-top: 0px;

  @include media(992){
    margin-top: 13px;
  }

  @include media(767){
    margin-top: 0px;
  }
  &__title{
    margin-top: 20px;
    font-family: Roboto, "sans-serif";
    font-weight: 400;
    font-size: 9px;
    color: #ACACAC;
    letter-spacing: 1px;
    margin-bottom: 3px;
  }

  &__alert{
    margin-top: 14px;
    margin-left: 8px;
  }

  &__input{

    @include media(992){
      margin-top: 20px;
    }

    @include media(767){
      margin-top: 7px;
    }
    margin-top: 7px;

    &_first{

      @include media(992){
        margin-top: 10px;
      }

      @include media(767){
        margin-top: 7px;
      }
      margin-top: 7px;
    }
  }

  &__buttons{
    margin-top: 41px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button{

    &_confirm{

    }

    &_cancel{
      margin-top: 30px;
      font-family: Roboto, "sans-serif";
      font-weight: 500;
      font-size: 17px;
      color: #C2C2C2;
      text-align: center;
    }
  }
}
</style>
